import React from 'react'
import ErrorBoundaryMessage from './errorBoundaryMessage'
import MainLayout from './mainLayout'

const ErrorPage = () => {
  return (
    <MainLayout>
      <ErrorBoundaryMessage/>
    </MainLayout>
  )
}

export default ErrorPage
