import { useDoc } from './useDoc'
import { useSelector } from 'react-redux'
import { auth } from '../utils/firebaseUtils'

export const useUser = () => {
  const options = useSelector(s => s.options)
  const email = auth.currentUser?.email || options.email
  const { data,loading,error } = useDoc(`users/${email}`)
  return { user: data,loading,error }
}
