export const lightgray = `#f4f4f4`
export const darkgray = `#808080`
export const blackGray = `#1c1c1c`
export const whiteYellow = `#e5e5e5`
export const color1 = `black` 
export const color2 = `white`
export const highlight = `blue`
export const footerColor = `#1c1c1c`

export const blue = `rgb(50,118,183)`
export const lightblue = `rgba(50,118,183,0.5)`
export const borderColor = `rgba(255, 255, 255, 0.125)`
export const excelColor = `rgba(29, 111, 66,0.9)`

export const selectedColor = `rgba(123,244,112,0.6)`
