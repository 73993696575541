import React from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'

const Message = ({ msg }) => {
  return (
    <MainLayout>
      <Container>
        <Img src="/images/buddeez.png"/>
        <Msg> {msg} </Msg>
      </Container>
    </MainLayout>
  )
}

export default Message 

const Container = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
`
const Img = styled.img`
  max-height:100px;
  object-fit:contain;
`
const Msg = styled.h2`
  color:var(--blue);
  max-width:60vw;
  font-size:1.5rem;
`
