import { db,parseDocSnapshot } from '../utils/firebaseUtils'
import { useDocument as useFirestoreDocument } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { toaster } from '../utils/toaster'

export const useDoc = path => {
  let [collection,docId] = path.split(`/`)
  if(!docId) docId = `abc`
    
  let [value,loading,error] = useFirestoreDocument(
    doc(db,collection,docId),
    { snapshotListenOptions: { includeMetadataChanges: true } },
  )
    
  if(error)
    toaster.error(error)
      
  if(docId === `abc`) return { data: {},loading: false,error }
  const data = value ? parseDocSnapshot(value) : {}
  return { data,loading,error }
}
