import React from 'react'
import styled from 'styled-components'
import Navigation from './navigation'
import Footer from './footer'

const MainLayout = ({ children }) => {
  return (
    <Container className="main-layout-component">
      <Navigation/> 
      <Content className="content-component">
        {children} 
      </Content>
      <Footer/>
    </Container>
  )
}

export default MainLayout 

const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  font-weight: 400;
  align-items: center;
  padding-bottom: 5em
  /* justify-content: center; */
`
const Content = styled.div`
  display: flex;
  box-sizing:border-box;
  width: 100vw;
  /* min-height: 70vh; */
  flex-direction: column;
  margin-top: 3vh;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
`

