import { lazy } from 'react'
import {
  Home,
  Calendar,
  LogIn,
  LogOut,
  Tool,
  User,
  Users,
  Key,
  BarChart2,
} from 'react-feather'

export const routes = [
  {
    path: `/`,
    label: `Home`,
    Icon: Home,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/home`)),
  },
  {
    path: `/`,
    label: `Tools`,
    Icon: Tool,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/home`)),
    hide: true,
  },
  // {
  //   path: `/tools/attendance`,
  //   label: `Attendance`,
  //   Icon: Calendar,
  //   roles: [`user`,`admin`],
  //   Component: lazy(() => import(`./components/attendance`)),
  //   hide: false,
  // },
  {
    path: `/tools/users`,
    label: `Users`,
    Icon: Users,
    roles: [`admin`],
    Component: lazy(() => import(`./components/users`)),
    hide: false,
  },
  {
    path: `/tools/employees`,
    label: `Employees`,
    Icon: Users,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/employees`)),
    hide: false,
  },
  {
    path: `/tools/employees/:employeeId`,
    label: `Employees`,
    Icon: Users,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/employee`)),
    hide: true,
  },
  {
    path: `/tools/events`,
    label: `Events`,
    Icon: Key,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/events`)),
    hide: false,
  },
  {
    path: `/tools/events/:eventId`,
    label: `Events`,
    Icon: Key,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/event`)),
    hide: true,
  },
  {
    path: `/tools/reports`,
    label: `Reports`,
    Icon: BarChart2,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/reports`)),
    hide: false,
  },
  {
    path: `/tools/reports/attendanceSummary`,
    label: `Attendance Summary Report`,
    Icon: BarChart2,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/attendanceSummary`)),
    hide: false,
  },
  {
    path: `/tools/reports/detail/:employeeId`,
    matcher: `/tools/reports/detail`,
    label: `Attendance Detail Report`,
    Icon: BarChart2,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/attendanceDetail`)),
    hide: false,
  },
  {
    path: `/tools/reports/incidents`,
    label: `Incident Count Report`,
    Icon: BarChart2,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/incidents`)),
    hide: false,
  },
  {
    path: `/tools/reports/fmla`,
    label: `FMLA Report`,
    Icon: BarChart2,
    roles: [`user`,`admin`],
    Component: lazy(() => import(`./components/fmla`)),
    hide: false,
  },
  {
    path: `/logout`,
    label: `Logout`,
    Icon: LogOut,
    Component: lazy(() => import(`./components/logout`)),
  },
  {
    path: `/`,
    label: `Login`,
    Icon: LogIn,
    Component: lazy(() => import(`./components/home`)),
  },
  {
    path: `/forgot_password`,
    label: `Forgot Password`,
    Icon: LogIn,
    Component: lazy(() => import(`./components/forgotPassword`)),
    hide: true,
  },
  {
    path: `/users`,
    label: `Forgot Password`,
    Icon: Users,
    roles: [`admin`],
    Component: lazy(() => import(`./components/users`)),
    hide: true,
  },
  {
    path: `tools/users/:email`,
    label: `User`,
    Icon: User,
    roles: [`admin`],
    Component: lazy(() => import(`./components/user`)),
    hide: true,
  },
]
