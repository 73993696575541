import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import optionsReducer from './slices/options.slice'
import endpointsReducer from './slices/endpoints.slice'

export default configureStore({
  reducer: { 
    options: optionsReducer,
    endpoints: endpointsReducer,
  },
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })],
})

