import React,{ useState } from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import styled from 'styled-components'
import LoginView from './loginView'
import StyledInput from './styledInput'
import { useCollection } from '../hooks/useCollection'
import Status from './status'
import { auth } from '../utils/firebaseUtils'
import { toaster } from '../utils/toaster'
import { useSelector } from 'react-redux'
import Button from './button'
import { blue } from '../utils/colors'

const Password = () => {
  const email = useSelector(s => s.options.email)
  const navigate = useNavigate()
  const { loading } = useCollection(`users`)
  const [password,setPassword] = useState(``)

  const handleSubmit = async() => {
    try{
      const login = await signInWithEmailAndPassword(auth,email,password)
      navigate(`/`)
    }catch(err){
      err.message = err.message.replace(`Firebase Error: Firebase: Error`,``)
      toaster.error(err.message)
    }
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`)
      handleSubmit()
  }

  return (
    <Status loading={loading}>
      <LoginView title="Login">
        <H3>{`Signing in as ${email}`}</H3>
        <StyledInput
          placeholder="Password"
          required
          onKeyUp={handleKeyUp}
          value={password}
          type="password"
          onChange={e => setPassword(e)}/>          
        <Button
          text="Enter"
          style={{ backgroundColor: blue }}
          onClick={handleSubmit}/>
        <Line/>
        <Options>
          <Link to="/forgot_password"><Option>Forgot password</Option></Link>
        </Options>
      </LoginView>
    </Status>
  )
}

export default Password 

const H3 = styled.h3`
  /* color:var(--blue); */
`
const Options = styled.div`
  width:100%;
  flex-grow:1;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  justify-content: center;
`
const Option = styled.button`
  background:transparent;
  border:none;
  color:var(--blue);
  cursor:pointer;
`

const Line = styled.div`
  margin-top:20px;
  border-bottom: 1px solid lightgray;
  width:100%;
  align-items: center;
  justify-content: center;
`
