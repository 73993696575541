import React from 'react'
import { Button as WakeflowButton } from 'wakeflow-design'
import { blue } from '../utils/colors'

const Button = props => {
  return (
    <WakeflowButton
      style={{
        backgroundColor: blue,
        marginRight: `5vw`,
        marginLeft: `5vw`,
        display: `flex`,
        alignSelf: `flex-end`,
      }}
      {...props}/>
  )
}

export default Button 

