import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <FooterCont>
      <Container>
        <Vertical>
        </Vertical>
        <SocialMedia>
        </SocialMedia>
      </Container>
    </FooterCont>
  )
}

const Link = ({ text,href }) => {
  return (
    <StyledLink target="_blank" rel="noopenere noreferrer" href={href}>{text.toLowerCase()}</StyledLink>
  )
}

export default Footer 


const FooterCont = styled.div`
  bottom: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`
const Container = styled.div`
  display: flex;
  background-color: var(--blue);
  /* backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%); */
  border-bottom: 1px solid var(--blue);
  flex:1;
  align-items: center;
  justify-content: center;
  color:white;
  border-radius:0;
  padding:0;
  position:relative;
  width: 100vw;
`
const StyledLink = styled.a`
  font-size:1.1rem;
  text-decoration:none;
  color:#FFFFFF80;
  margin:2px;
`
const Img = styled.img`
  margin:15px 0px;
  max-width:15vw;
  @media (max-width: 600px) {
    max-width:100px;
  }
`
const Vertical = styled.div`
  margin:1vh 3vh;
  flex-direction:column;
  align-items:flex-start;
  flex-grow:1;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const SocialMedia = styled.div`
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:center;
  gap:10px;
  margin:1vh 3vh;
  align-items: center;
  width: 100%;
`

