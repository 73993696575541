import React from 'react'
import './styles.css'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import Router from './components/router'
import { Toaster } from 'react-hot-toast'
import ErrorPage from './components/errorPage'

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage} >
      <BrowserRouter basename="/">
        <Toaster position="top-center"/>
        <Router/>
      </BrowserRouter>
    </ErrorBoundary>

  )
}

export default App
