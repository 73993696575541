export const errorStyle = {
  style: {
    color: `white`,
    backgroundColor: `#f44336`,
  },
}

export const successStyle = {
  style: {
    color: `white`,
    backgroundColor: `#4bb543`,
  },
}

export const infoStyle = {
  style: {
    color: `white`,
    backgroundColor: `black`,
  },
}
