import React from 'react'
import { useUser } from '../hooks/useUser'
import Message from './message'
import Email from './email'
import { auth } from '../utils/firebaseUtils'
import Password from './password'
import { useLocation } from 'react-router-dom'

const ProtectedRoute = ({ roles,children }) => {
  const { user } = useUser()
  const { role,status,email } = user
  const location = useLocation()
  if(location.pathname === `/logout` || location.pathname === `/forgot_password`) return children
  if (!auth.currentUser && !email) return <Email />
  if (status === `pending`)
    return (
      <Message msg="Your account is pending. We will email you once your request has been reviewed." />
    )
  if (!auth.currentUser) return <Password />
  if (roles && !roles.includes(role))
    return (
      <Message msg="You cannot access this route. Please ask a system admin for the required premissions." />
    )
  return children
}

export default ProtectedRoute
