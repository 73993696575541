import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { Home } from 'react-feather'
import BurgerMenu from './burgerMenu'
import { Link,useLocation } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import { routes } from '../routes'

const Navigation = () => {
  const { user } = useUser()
  const location = useLocation()
  const { pathname } = location

  let options = routes
    .filter(r => !r.roles || r.roles.includes(user.role))
    .filter(r => !r.path.includes(`/reports/`))
  if(!user.email) options = options.filter(r => r.label === `Login`)
  if(user.email) options = options.filter(r => r.label !== `Login`)
  options = options
    .filter(o => !o.hide)
    .filter(o => !o.roles || o.roles.includes(user.role))

  const title = 
  routes.find(r => pathname.includes(r.matcher))?.label ||
  routes.find(r => r.path.includes(pathname))?.label || 
  routes.filter(r => r.path !== `/`).find(r => pathname.includes(r.path))?.label

  return (
    <Container>
      <Link to="/"><StyledHome/></Link>
      <Title>{title}</Title>
      {/* {options
        .map((option,index) => {
          const { route,label,Icon } = option
          const { pathname } = location
          return (
            <Option
              active={pathname === route || (route !== `/` && pathname.includes(route))}
              onClick={() => navigate(route)}
              key={index}>
              <Icon size={16} />
              <Heading>{label}</Heading>
            </Option>
          )
        })} */}
      <Box><BurgerMenu options={options} /></Box>
    </Container>
  )
}

export default Navigation
const StyledHome = styled(Home)`
  color:white;
  height:45px;
`
const Container = styled.div`
  display:flex;
  flex:0;
  scrollbar-width: none;
  &::-webkit-scrollbar { display:none;}
  box-sizing:border-box;
  align-items:unset;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  gap:0px;
  /* background-color: var(--blue); */
  /* backdrop-filter: blur(16px) saturate(180%); */ //!! Backdrop filter breaks burger menu?
  /* -webkit-backdrop-filter: blur(16px) saturate(180%); */
  border-bottom: 1px solid var(--blue);
  background-color: var(--blue);
  width: 100%;
`
const Box = styled.div`
  align-items: center;
  justify-content: center;
  `
const Logo = styled.img`
  height:45px;
  margin: 0 10px;
  flex:1;
`
const Title = styled.div`
    font-weight: 400;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
`

const Option = styled.div`
  gap:10px;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  font-size:0.8rem;
  padding:5px 15px;
  min-width:170px;
  box-sizing:border-box;
  border-radius:40px;
  border-radius:0;
  flex:0;
  width: 100%;
  /* border-bottom:${p => p.active ? `1px solid black` : ``}; */
`
const Heading = styled.div`
  font-weight:500;
  box-sizing:border-box;
  font-size:1.1rem;
  margin:0;
  padding:0;
  flex:0;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
`
