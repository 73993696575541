import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { firebaseConfig } from '../firebaseConfig'
import { 
  getFirestore,doc,
  getDoc as getFirestoreDoc,
  deleteDoc as deleteFirestoreDoc,
  setDoc as setFirestoreDoc, 
} from 'firebase/firestore'

const app = initializeApp(firebaseConfig)

export const auth = getAuth()
export const db = getFirestore(app)
export default app

export const parseCollectionSnapshot = snapshot => {
  const output = []
  snapshot.forEach(item => {
    output.push(parseDocSnapshot(item))
  })
  return output
}
export const parseDocSnapshot = snapshot => {
  if(!snapshot.data) return null
  const data = snapshot.data()
  return{
    id: snapshot.id,
    ...data,
  }
}
export const getDoc = async path => {
  const [collection,docId] = path.split(`/`)
  const ref = await getFirestoreDoc(doc(db,collection,docId))
  return parseDocSnapshot(ref)
}
export const setDoc = async(path,data) => {
  const [collection,docId] = path.split(`/`) 
  const docRef = doc(db,collection,docId)
  await setFirestoreDoc(docRef,data,{ merge: false })
}
export const updateDoc = async(path,data) => {
  const [collection,docId] = path.split(`/`) 
  const docRef = doc(db,collection,docId)
  await setFirestoreDoc(docRef,data,{ merge: true })
}
export const deleteDoc = async path => {
  const [collection,docId] = path.split(`/`) 
  const docRef = doc(db,collection,docId)
  await deleteFirestoreDoc(docRef)
}

