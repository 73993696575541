import React from 'react'
import styled from 'styled-components'

const ErrorBoundaryMessage = ({ message }) => {
  return (
    <Container>
      <Content>
        <Image src="" />
        {message && <Title>{message}</Title>}
        {!message && <Title>Something went wrong...</Title>}
        <Subtitle>Please contact support</Subtitle>
      </Content>
    </Container>
  )
}

export default ErrorBoundaryMessage

const Container = styled.div`
  display:flex;
  padding:20px;
  flex-direction:column;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-radius: 12px;
  border: 1px solid var(--blue);
  box-sizing: content-box;
  padding: 30px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  @media only screen and (max-width:600px){
    padding: 0;
  }
`
const Image = styled.img`
  width: clamp(150px,20rem,300px);
  animation: spin 5s linear infinite;
  @keyframes spin{
     0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
`
const Title = styled.h1`
  color: red;
  text-align:center;
  font-size:2rem;
`
const Subtitle = styled(Title)`
  font-size:1.5rem;
  color:gray;
`

