import React from 'react'
import styled from 'styled-components'

const StyledInput = ({ onChange,...rest }) => {
  return (
    <Container 
      {...rest}
      onChange={e => onChange(e.target.value)}/>
  )
}

export default StyledInput 

const Container = styled.input`
  background-color:rgb(220,220,220);
  border-radius:12px;
  padding:15px;
`

