import React from 'react'
import styled from 'styled-components'
import Loader from './loader'

const Status = ({ loading,children }) => {
  if(loading) return <Loader/>
  else return children
}

export default Status 

