import React from 'react'
import styled from 'styled-components'
import { useNavigate,useLocation } from 'react-router-dom'

const BurgerMenu = ({ options }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleClick = () => {
    const toggler = document.querySelector(`.menu__toggler`)
    const menu = document.querySelector(`.menu`)
    if(!menu.classList.contains(`active`)) menu.style.display = `flex`
    setTimeout(() => {
      toggler.classList.toggle(`active`)
      menu.classList.toggle(`active`)
      if(!menu.classList.contains(`active`)) setTimeout(() => menu.style.display = `none`,500)
    },0)
  }
  return ( 
    <Container>
      <Options className="menu">
        {options.map((option,index) => {
          const { path,label,Icon } = option
          const { pathname } = location
          return (
            <Option 
              active={pathname === path || (path !== `/` && pathname.includes(path))}
              onClick={() => {
                document.querySelector(`.menu__toggler`).classList.remove(`active`)
                document.querySelector(`.menu`).classList.remove (`active`)
                navigate(path)
              }}
              key={index}>
              {Icon && <Icon size={16}/>}
              <Heading>{label}</Heading>
            </Option>
          )
        })}
      </Options>
      <MenuToggler 
        onClick={handleClick}
        className="menu__toggler"><span></span></MenuToggler>
    </Container>
  )
}

export default BurgerMenu 

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex:0;
  align-items: center;
  overflow-x:hidden;
  align-items: center;
  justify-content: center;
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 100%;
`

const Options = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
  top:0;
  height:100vh;
  right: calc(-1 * max(40vw,350px));
  z-index: 998;
  color: white;
  /* background-color:rgba(0,0,0,0.9); */
  background-color: var(--blue);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-left: 1px solid var(--blue);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
  width: max(40vw,350px);
  height: 100%;
  padding: calc(max(40vw,350px) * 0.25);
  padding-top:10vh;
  padding-right:2vh;
  flex-direction: column;
  justify-content: flex-start;
  transition: 300ms right cubic-bezier(0.77, 0, 0.175, 1);
  display:none;
  &.active {
    right: 0;
  }
  p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`
const MenuToggler = styled.div`
  position: absolute;
  top: 25px;
  right: 40px;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 28px;
  width: 28px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  span,
  span::before,
  span::after {
    position: absolute;
    content: '';
    width: 28px;
    height: 2.5px;
    background: white;
    border-radius: 20px;
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  span::before {
    top: -8px;
  }
  
  span::after {
    top: 8px;
  }
  
  &.active > span  {
    background: transparent;
  }

  &.active > span::before,
  &.active > span::after {
    background: white;
    top: 0px;
  }

  &.active > span::before  {
    transform: rotate(-225deg);
  }

  &.active > span::after  {
    transform: rotate(225deg);
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }
`
const Option = styled.div`
  display:flex;
  width: 100%;
  gap:20px;
  align-items: center;
  justify-content: center;
  justify-content:flex-start;
  cursor:pointer;
  padding:15px 15px;
  box-sizing:border-box;
  border-radius:40px;
  border-radius:0;
  flex:0;
`
const Heading = styled.div`
  box-sizing:border-box;
  font-size:1.3rem;
  margin:0;
  align-items: center;
  justify-content: center;
  padding:0;
  flex:0;
  white-space: nowrap;
`

