import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const name = `options`


const slice = createSlice({
  name,
  initialState: { 
    email: ``,
    selectedDomains: [],
    productFilter: [],
    thicknessFilter: [],
    heightFilter: [],
    employeeIds: [],
    widthFilter: [],
    page: 0,
    perPage: 30,
    sortBy: `LFDAT`,
    sortAsc: true,
    searchTerm: ``,
    rowSearchTerm: ``,
    rowSortBy: `LFDAT`,
    rowSortAsc: false,
    userSearchTerm: ``,
    userSortBy: `email`,
    userSortAsc: true,
    unitPriceDomain: ``,
    customerNumber: ``,
    startDate: moment().subtract(30,`days`).format(`YYYY-MM-DD`),
    endDate: moment().format(`YYYY-MM-DD`),
    minPoints: null,
    maxPoints: null,
    eventTypes: [],
    employeeStatus: [`active`],
    eventStatus: [`active`],
    showFilter: {},
  },
  reducers: {
    updateOptionsState(state,action){
      state = { ...state,...action.payload }
      return state
    },
    setSelectedDomains(state,action){
      state.selectedDomains = action.payload
    },
    setProductFilter(state,action){
      state.productFilter = action.payload
    },
    setThicknessFilter(state,action){
      state.thicknessFilter = action.payload
    },
    setHeightFilter(state,action){
      state.heightFilter = action.payload
    },
    setWidthFilter(state,action){
      state.widthFilter = action.payload
    },
    setPage(state,action){
      state.page = Number(action.payload)
    },
    setSortBy(state,action){
      state.sortBy = action.payload
    },
    setSortAsc(state,action){
      state.sortAsc = !!action.payload
    },
    setRowSortBy(state,action){
      state.rowSortBy = action.payload
    },
    setRowSortAsc(state,action){
      state.rowSortAsc = !!action.payload
    },
    setSearchTerm(state,action){
      state.searchTerm = action.payload
    },
    setRowSearchTerm(state,action){
      state.rowSearchTerm = action.payload
    },
    setUserSortBy(state,action){
      state.userSortBy = action.payload
    },
    setUserSortAsc(state,action){
      state.userSortAsc = !!action.payload
    },
    setUserSearchTerm(state,action){
      state.userSearchTerm = action.payload
    },
    setUnitPriceDomain(state,action){
      state.unitPriceDomain = action.payload
    },
    setCustomerNumber(state,action){
      state.customerNumber = action.payload
    },
  },
})

export default slice.reducer
export const {
  updateOptionsState,setPage,setSelectedDomains,setProductFilter,setThicknessFilter,setRowSearchTerm,
  setHeightFilter,setWidthFilter,setSortAsc,setSortBy,setSearchTerm,
  setRowSortAsc,setRowSortBy,setUnitPriceDomain,setUserSearchTerm,setUserSortAsc,setUserSortBy,setCustomerNumber,
} = slice.actions
export const selectSelectedDomains = state => state[name].selectedDomains
export const selectProductFilter = state => state[name].productFilter
export const selectThicknessFilter = state => state[name].thicknessFilter
export const selectHeightFilter = state => state[name].heightFilter
export const selectWidthFilter = state => state[name].widthFilter
export const selectPage = state => state[name].page
export const selectPerPage = state => state[name].perPage
export const selectSortBy = state => state[name].sortBy
export const selectSortAsc = state => state[name].sortAsc
export const selectRowSortBy = state => state[name].rowSortBy
export const selectRowSortAsc = state => state[name].rowSortAsc
export const selectSearchTerm = state => state[name].searchTerm
export const selectRowSearchTerm = state => state[name].rowSearchTerm
export const selectUnitPriceDomain = state => state[name].unitPriceDomain
export const selectUserSortBy = state => state[name].userSortBy
export const selectUserSortAsc = state => state[name].userSortAsc
export const selectUserSearchTerm = state => state[name].userSearchTerm
export const selectCustomerNumber = state => state[name].customerNumber
export const selectOptions = state => state[name]
