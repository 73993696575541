
export const dev = {
  apiKey: `AIzaSyC1A1cO9iAKPelzADNpmZpMF2SiMYiuhDc`,
  authDomain: `attendance-tracker-dev-12480.firebaseapp.com`,
  projectId: `attendance-tracker-dev-12480`,
  storageBucket: `attendance-tracker-dev-12480.appspot.com`,
  messagingSenderId: `501383147933`,
  appId: `1:501383147933:web:f8acf708fa748198107f74`,
  measurementId: `G-Y7H6QL6QPT`,
}

export const prod = {
  apiKey: `AIzaSyBdpjQTaQLQcHpHR8NKjsxROg63UcP9v0M`,
  authDomain: `attendance-tracker-340720.firebaseapp.com`,
  projectId: `attendance-tracker-340720`,
  storageBucket: `attendance-tracker-340720.appspot.com`,
  messagingSenderId: `777185800944`,
  appId: `1:777185800944:web:71df11d17c3b502f794835`,
  measurementId: `G-D7M001Z1TD`,
}

export const firebaseConfig = { dev,prod }[process.env.REACT_APP_FIREBASE_CONFIG || `dev`]
