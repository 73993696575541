import React from 'react'
import styled from 'styled-components'
import { Loader as WakeflowDesignLoader } from 'wakeflow-design'

const Loader = () => {
  return (
    <Container>
      <WakeflowDesignLoader 
        image={`/images/buddeez.png`}
        color="white"
        alt="Loading"/>
    </Container>
  )
}

export default Loader

const Container = styled.div`
  border-radius: 20px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px){
    width: 200px;
  }
`
