import React,{ lazy,Suspense } from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import { Routes } from 'react-router-dom'
import Loader from './loader'
import ProtectedRoute from './protectedRoute'
import { routes } from '../routes'

const LazyErrorPage = lazy(() => import(`./errorPage`))
const LazyNotFound = lazy(() => import(`./notFound`))

const Router = () => {
  return (
    <Container>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map((route,index) => {
            const { Component,path,roles = [] } = route
            return (
              <Route
                key={index}
                exact
                path={path}
                element={<ProtectedRoute roles={roles}>
                  <Component/>
                </ProtectedRoute>}></Route>
            )
          })}
          <Route exact path="/errorPage" element={<LazyErrorPage/>}></Route>
          <Route path="*" element={<LazyNotFound/>}></Route>
        </Routes>
      </Suspense>
    </Container>

  )
}

export default Router

const Container = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`
