import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { auth } from '../../utils/firebaseUtils'

const name = `endpoints`

export const getEndpoint = createAsyncThunk(`${name}/get`,
  async(endpoint,{ getState,dispatch }) => {
    dispatch(pending({ endpoint }))
    const state = getState()
    if(state[endpoint] && state[endpoint].status === `loading`) return

    while(!auth.currentUser){
      console.log(`waiting`)
      await new Promise(res => setTimeout(res,500))
    }
    
    const idToken = await auth.currentUser.getIdToken(false)

    try{
      // const idToken = await auth.currentUser.getIdToken(false)
      // console.log(`fetching ${endpoint}`);
      const axiosConfig = {
        method: `get`,
        url: `${process.env.REACT_APP_API_URL}${endpoint}`,
        headers: { 'Authorization': `Bearer ${idToken}` },
      }
      // console.log(axiosConfig)
      const response = await axios(axiosConfig)
      // console.log(response.data)
      const { data } = response
      dispatch(fulfilled({ endpoint,data }))
    }catch(err){
      console.log(err.message)
      console.log(err.response.data)
      dispatch(rejected({ endpoint,error: { msg: err.response.data.message } }))
    }
  },
)
const slice = createSlice({
  name,
  initialState: {},
  reducers: {
    pending(state,action){
      const { endpoint } = action.payload
      if(!state[endpoint])state[endpoint] = {}
      state[endpoint].status = `loading`
      return state
    },
    fulfilled(state,action){
      const { endpoint,data } = action.payload
      if(!state[endpoint])state[endpoint] = {}
      state[endpoint].status = `succeeded`
      state[endpoint].data = data
      return state
    },
    rejected(state,action){
      const { endpoint,error } = action.payload
      if(!state[endpoint])state[endpoint] = {}
      state[endpoint].status = `failed`
      state[endpoint].error = error
      return state
    },
  },
})

export default slice.reducer
export const { pending,fulfilled,rejected } = slice.actions
export const selectEndpoints = state => state[name]
