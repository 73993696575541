import { db,parseDocSnapshot } from '../utils/firebaseUtils'
import { useCollection as useFirestoreCollection } from 'react-firebase-hooks/firestore'
import {
  query,collection,getDocs,limit,orderBy,startAfter,limitToLast, 
} from 'firebase/firestore'
import { toaster } from '../utils/toaster'


export const useCollection = collectionName => {
  let [value,loading,error] = useFirestoreCollection(
    collection(db,collectionName)
    ,{ snapshotListenOptions: { includeMetadataChanges: true } },
  )
  
  if(error)
    toaster.error(error)
  if(value)
    value = value.docs.map(parseDocSnapshot)
  
  return { data: value || [],loading,error }
}

export const useCollectionPages = async(collectionName,field,perPage,last,action) => {
  const collectionRef = await collection(db,collectionName)
  let data = await paginationAction(collectionRef,field,perPage,last,action)
  const querySnapshot = await getDocs(data)
  let result = []
  querySnapshot.forEach(doc => result.push(doc.data()))
  return { data: result }
}

const paginationAction = async(collectionRef,field,perPage,last,action) => {
  if(action === `next`)
    return query(collectionRef,orderBy(field),startAfter(last[field]),limit(perPage))
  else if(action === `prev`)
    return query(collectionRef,orderBy(field),endBefore(last[field]),limitToLast(perPage))
  else
    return query(collectionRef,orderBy(field),limit(perPage))
}
