import React,{ useState } from 'react'
import styled from 'styled-components'
import LoginView from './loginView'
import StyledInput from './styledInput'
import { toaster } from '../utils/toaster'
import { getDoc,setDoc } from '../utils/firebaseUtils'
import { updateOptionsState } from '../redux/slices/options.slice'
import { useDispatch } from 'react-redux'
import Button from './button'
import { blue } from '../utils/colors'

const Email = () => {
  const [email,setEmail] = useState(``)
  const dispatch = useDispatch()

  const handleSubmit = async() => {
    if(!email.includes(`@buddeez.com`) && !email.includes(`@wakeflow.io`) && email !== `ryanhall2015@gmail.com` && email !== `ryan.hall.buddeez@gmail.com` && email !== `hallsteve320@gmail.com` && email !== `mschall530@gmail.com`)
      return toaster.error(`Sorry, only @buddeez.com email addresses can log in here`)
    const user = await getDoc(`users/${email}`)
    if(!user.email)
      try {
        await setDoc(`users/${email}`,{ 
          email,
          status: `pending`,
          createdAt: new Date(),
          role: `user`, 
        })
      } catch(error) {
        console.log(error)
      }
    dispatch(updateOptionsState({ email }))
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`)
      handleSubmit()
  }

  return (
    <LoginView title="Login">
      <InputCont>
        <StyledInput
          type="email"
          placeholder="Email"
          value={email}
          onKeyUp={handleKeyUp}
          onChange={e => setEmail(e)}/>          
        <Button
          text="Enter"
          style={{ backgroundColor: blue }}
          onClick={handleSubmit}/>
      </InputCont>
    </LoginView>
    
  )
}

export default Email 

const InputCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  justify-content: center;
  gap:10px;
`
